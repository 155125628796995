/* eslint-disable jsx-a11y/anchor-is-valid */
import logoDarkBg from './logo-dark-bg.svg';
import hero2 from './hero-2.svg';
import agoricLogo from './agoric-logo.svg';
import polkadotLogo from './polkadot-logo.svg';
import flowLogo from './flow-logo.svg';
import ethereumLogo from './ethereum-logo.svg';
import rchainLogo from './rchain-logo.png';
import tenplusLogo from './tenplus-logo.png';

import victor from './b57d3eab-6e01-4658-85fe-e1db9ed226fd.jpg';
import jennifer from './e83a1ed2-7bec-48b6-af22-67993376ee64.jpg';
import jacob from './2b63ad8e-8de9-4c26-bffa-8a5d0dc81626.jpg';




const stats = [
    { label: 'First investment', value: '2015' },
    { label: 'Investment range', value: '$50k - $1M' }
]
const logos = [
    { name: 'Ethereum', url: ethereumLogo },
    { name: 'Polkadot', url: polkadotLogo },
    { name: 'RChain', url: rchainLogo },
    { name: 'Flow', url: flowLogo },
    { name: 'Agoric', url: agoricLogo },
    { name: '10 plus', url: tenplusLogo }
]


const people = [
    {
        name: 'Victor Koff',
        role: 'Managing partner',
        imageUrl: victor,
    }, {
        name: 'Jennifer Tan',
        role: 'Partner',
        imageUrl: jennifer,
    }, {
        name: 'Jacob Hansson',
        role: 'Partner',
        imageUrl: jacob,
    }
]


const Leadership = () => {
    return (
        <div className="mt-32">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
                    <div className="lg:col-span-2">
                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                            {people.map((person) => (
                                <li key={person.name}>
                                    <div className="flex items-center space-x-4 lg:space-x-6">
                                        <img className="w-16 h-16 rounded-full lg:w-20 lg:h-20" src={person.imageUrl} alt="" />
                                        <div className="font-medium text-lg leading-6 space-y-1">
                                            <h3>{person.name}</h3>
                                            <p className="text-indigo-600">{person.role}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet our leadership</h2>
                        <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                            We are a globally diverse team with backgrounds in software engineering, finance and entrepreneurship. We have been involved in the blockchain space since 2015 as investors and technologists.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Testimonals = () => {

    return (
        <div className="relative mt-20">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                <div className="relative sm:py-16 lg:py-0">
                    <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                        <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                        <svg
                            className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                            width={404}
                            height={392}
                            fill="none"
                            viewBox="0 0 404 392"
                        >
                            <defs>
                                <pattern
                                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                        </svg>
                    </div>
                    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                        {/* Testimonial card*/}
                        <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                            <img
                                className="absolute inset-0 h-full w-full object-cover"
                                src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
                                alt=""
                            />
                            <div className="absolute inset-0 bg-indigo-500" style={{ mixBlendMode: 'multiply' }} />
                            <div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" />
                            <div className="relative px-8">
                                <div>
                                    <img
                                        className="h-16"
                                        src={victor}
                                        alt="Victor Koff"
                                        style={{ borderRadius: '50%' }}
                                    />
                                </div>
                                <blockquote className="mt-8">
                                    <div className="relative text-lg font-medium text-white md:flex-grow">
                                        <svg
                                            className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400"
                                            fill="currentColor"
                                            viewBox="0 0 32 32"
                                            aria-hidden="true"
                                        >
                                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        <p className="relative">
                                            What makes me excited about blockchain technology is the disruptive nature of not only the financial system, but for all forms of digital ownership - it is the ultimate enabler to speak truth to power.
                                        </p>
                                    </div>

                                    <footer className="mt-4">
                                        <p className="text-base font-semibold text-indigo-200">Victor Koff - Managing partner</p>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                    {/* Content area */}
                    <div className="pt-12 sm:pt-16 lg:pt-20">
                        <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                            On a mission to disrupt the global economy
                        </h2>
                        <div className="mt-6 text-gray-500 space-y-6">
                            <p className="text-lg">
                                We believe blockchain technology is on a projectile to disrupt and ultimately replace vast segments of the global economy. This space is however hard for traditional investors to navigate, but our long and diverse experience make us especially suited to find the real innovators.
                            </p>
                            <p className="text-base leading-7">
                                Apart from financing, we also contribute as Validators/Node operators in many of the networks and is a loyal partner through testnet, bootstrapping and mature main nets.
                                <br />
                                Several of our partners come from a software engineering background and is active in various communities that we love to introduce to exciting new technology.
                            </p>
                        </div>
                    </div>

                    {/* Stats section */}
                    <div className="mt-10">
                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                            {stats.map((stat) => (
                                <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                                    <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                                    <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>

    )
}


const Contact = () => {
    return (
        <div className="mt-32">
            <div className="relative sm:py-16">
                <div aria-hidden="true" className="hidden sm:block">
                    <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                    <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
                        <defs>
                            <pattern
                                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                    </svg>
                </div>
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="relative rounded-2xl px-6 py-10 bg-indigo-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                        <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                            <svg
                                className="absolute inset-0 h-full w-full"
                                preserveAspectRatio="xMidYMid slice"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 1463 360"
                            >
                                <path
                                    className="text-indigo-500 text-opacity-40"
                                    fill="currentColor"
                                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                />
                                <path
                                    className="text-indigo-700 text-opacity-40"
                                    fill="currentColor"
                                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                />
                            </svg>
                        </div>
                        <div className="relative">
                            <div className="sm:text-center">
                                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                    Have an interesting project?
                                </h2>
                                <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-200">
                                    We are always looking to connect with great teams working on disruptive challenges. For investment inquiries, please send us your white papers & investment opportunities.
                                </p>
                            </div>
                            <form action="#" className="mt-12 sm:col-span-2 sm:flex sm:justify-center">
                                <div className="mt-4 sm:mt-0 sm:ml-3">
                                    <a
                                        href="mailto:info@functorcapital.com"
                                        className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                                    >
                                        Contact us
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const LogoCloud = () => {

    return (
        <div className="mt-32">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                    <div>
                        <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                            Our current portfolio
                        </h2>
                        <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                            Our investment horizon is unusual long which is a reflection on our deeper understanding of the exceptional engineering and research challenges facing ahead. We also understand that to deliver on these promises exceptional individuals need to come together as a team.
                        </p>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                        {logos.map((logo) => (
                            <div key={logo.name} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src={logo.url} alt={logo.name}
                                // style={{ filter: "grayscale(100%)" }} 
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}



const Footer = () => {

    return (
        <footer className="bg-functorblue">
            <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <p className="mt-8 text-center text-base text-gray-400">&copy; {new Date().getUTCFullYear()} Functor Capital. All rights reserved.</p>
            </div>
        </footer>
    )
}

const Hero = () => {

    return (

        <div className="relative overflow-hidden">
            <>
                <div className="bg-functorblue pt-6">
                    <nav
                        className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                        aria-label="Global"
                    >
                        <div className="flex items-center flex-2">
                            <div
                                className="flex items-center justify-between w-full md:w-auto"
                            >
                                <a href="#">
                                    <span className="sr-only">Functor Capital</span>
                                    <img
                                        className="h-8 w-auto sm:h-10"
                                        src={logoDarkBg}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="md:flex md:items-center md:space-x-6">
                            <a href="mailto:info@functorcapital.com"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-gray-700"
                            >
                                Contact us
                                </a>
                        </div>
                    </nav>
                </div>
            </>

            <div className="pt-10 bg-functorblue sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                            <div className="lg:py-24">
                                <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                    <span className="block">We invest</span>
                                    <span className="block text-indigo-400">you build</span>
                                </h1>
                                <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                    We invest globally into the next generation of computing, financial services and crypto assets.
                                    </p>
                            </div>
                        </div>
                        <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                                <img
                                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                    src={hero2}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >

    )
}

const Landing = () => {
    return (
        <div className="bg-white">
            <main>
                <Hero />
                <Testimonals />
                <LogoCloud />
                <Leadership />
                <Contact />
            </main>

            <Footer />
        </div>
    )
}

export default Landing;